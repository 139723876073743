<template>
    <div class="flex min-h-screen flex-col justify-center px-6 py-12 lg:px-8">
        <slot />
        <UiDialog />
        <UiToastWrapper />
    </div>
</template>

<script setup lang="ts">
</script>
